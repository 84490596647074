import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const DemoPage = () => (
  <Layout>
    <SEO title="Emailkick Demo" />
        <div className="w-screen mx-auto pt-12">
            <div className="w-full">
                <iframe className="w-full min-h-screen" src="https://app.cal.com/athul/emailkick" frameBorder={0} allowFullScreen></iframe>
            </div>
        </div>
  </Layout>
)

export default DemoPage
